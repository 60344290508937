export const realtorData = {
  email: 'relocate@unne.cl',
  phone:'56942125789'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'relocate@unne.cl',
  phone:'942125789',
  wsp:'942125789',
}
